.dropdown-container {
  text-align: left;
  border: 1px solid #ccc;
  position: relative;
  border-radius: 5px;
  width: 300px;
}

.dropdown-input {
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;
  padding-right: 10px;
}

.search-box{
  display:flex;
  flex-direction: column;
}
.search-function{
  display:flex;
  flex-direction: row;
  gap:5px
}
