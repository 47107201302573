.single-pin-wrapper {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    padding-bottom: 50px;
}

.single-pin {
    display: grid;
    column-gap: 30px;
    width: 75%;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    box-shadow: 0 0 10px lightgray;
    border-radius: 30px;
    justify-content: space-between;
    align-items: center;
}

.single-pin-image {
    width: 100%;
    max-height: 700px;
    min-height: 200px;
    object-fit: cover;
    /* object-fit: contain; */
    border-radius: 30px 0 0 30px;
}

.single-pin-details {
    /* border: 2px solid green; */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    padding-top: 50px;
    /* padding-right: 30px; */

}

.single-pin-ellipsis {
    border-radius: 50%;
    border-color: transparent;
    background-color: white;
    font-size: 20px;
    /* margin-right: 15px; */
    cursor: pointer;
}

.single-pin-ellipsis:hover {
    background-color: #F0F0F0;
}

.single-pin-edit-board {
    display: flex;
    justify-content: space-between;
    padding-bottom: 25px;
    position: relative;

}

.single-pin-edit-board-button {
    background-color: red;
    border-radius: 30px;
    border-color: transparent;
    color: white;
    padding: 10px;
    font-size: 15px;
    cursor: pointer;
}

.single-pin-edit-board-button:hover{
    background-color: rgb(203, 3, 3);
}

.single-pin-edit-board-form {
    display: flex;
    width: 100%;
    column-gap: 10px;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
}

.single-pin-title {
    font-weight: 500;
    font-size: 30px;
}

.single-pin-profile-image {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    object-fit: cover;
}

.single-pin-profile-default {
    font-size: 45px;
}

.single-pin-owner-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.single-pin-profile-info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 80%;
    flex-wrap: wrap;
    column-gap: 5px;
}

.single-pin-owner-name-followers {
    width: 75%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.single-pin-owner-name-followers>p {
    margin: 0;
    padding: 0;
    font-size: 12px;
}

.single-pin-owner-link {
    font-weight: 600;
    text-decoration: none;
    color: black;
    font-size: 14px;
}

.single-pin-follow-button,
.single-pin-following-button,
.single-pin-image-button {
    border-radius: 30px;
    border-color: transparent;
    padding: 10px;
    font-size: 15px;
}

.single-pin-follow-button {
    background-color: #F0F0F0;
    color: black;
}

.single-pin-following-button {
    background-color: black;
    color: white;
}

.single-pin-image-wrapper {
    position: relative;
}

.single-pin-image-button {
    position: absolute;
    bottom: 25px;
    left: 25px;
    background-color: rgb(255 255 255 / 0.75);
    cursor: pointer;
}

.single-pin-image-button>* {
    padding-right: 5px;
}

.single-pin-edit-board-form-select {
    border: 1px solid white;
    cursor: pointer;
    font-size: 15px;
}

.dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    width: 300px;
    z-index: 2;
    border: 1px solid rgba(0, 0, 0, 0.04);
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14);
}

.dropdown-ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.dropdown-list {
    padding: 8px 12px;
}

.pin-with-error{
    display:flex;
    justify-content: center;
    align-items: center;
}

.dropdown-list:hover {
    background-color: rgba(0, 0, 0, 0.14);
    cursor: pointer;
}

.dropdown-container-edit-pin {
    text-align: left;
    border: none;
    position: relative;
    border-radius: 5px;
}

.dropdown-input {
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    user-select: none;
}

.dropdown-menu-edit-pin {
    position: absolute;
    background-color: white;
    border-radius: 5px;
    width: 100%;
    transform: translateY(4px);
    overflow: auto;
    max-height: 150px;
}

.dropdown-item {
    padding: 5px;
    cursor: pointer;
}

.dropdown-item:hover {
    background-color: #9fc3f870
}

.dropdown-item.selected {
    background-color: #5A5A5A;
    color: #fff;
}

.search-box {
    padding: 5px;
    background-color: #eee;
}

.search-box input {
    width: 100%;
    box-sizing: border-box;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.single-pin-edit-board-button {
    /* padding-right: 5px; */
    margin-right: 15px;
}


.unfollow-individual-pin-page{
    margin-right: 15px;
}


.margin-right-15{
    /* margin-right: 15px; */
}
