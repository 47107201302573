.profile-board-images-wrapper {
    display: grid;
    grid-template-columns: 154px 79px;
    column-gap: 2px;
    width: 235px;
    height: 150px;
}

.profile-board-cover-image{
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 20px 0 0 20px;
}

.profile-additonal-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.profile-additonal-image.profile-image1 {
    border-radius: 0 20px 0 0;
}

.profile-additonal-image.profile-image2 {
    border-radius: 0 0 20px 0;
}

.profile-board-images-wrapper > div {
    height: 150px;
}

.profile-board-additional-image-wrapper {
    display: grid;
    grid-template-rows: 74px 74px;
    row-gap: 2px;
}

.profile-boards-all {
    display: flex;
    gap: 20px;
    padding: 20px 50px;
    justify-content: center;
    flex-wrap: wrap;
    cursor: pointer;
}

.profile-board-info {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    padding-top: 10px;
    row-gap: 5px;
}

.profile-board-info > div > * {
    /* border: 2px solid red; */
    margin: 0;
}

.profile-board-name {
    font-weight: 500;
}

.profile-pins-on-board {
    font-size: 13px;
}

.profile-board-pics {
    position: relative;
}

.profile-board-lock-icon-wrapper {
    position: absolute;
    left: 10px;
    top: 10px;
    background-color: white;
    border-radius: 50%;
    padding: 5px;
}

.profile-edit-board-icon.hidden {
    display: none;
}

.profile-boards-wrapper {
    position: relative;
}

.profile-edit-board-icon {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: white;
    border-radius: 50%;
    padding: 10px;
}

.profile-edit-board-icon:hover {
    background-color: #F0F0F0;
}

.profile-board-link {
    text-decoration: none;
    color: black;
}