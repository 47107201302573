.user-category-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    text-align: center;
    display: flex;
    padding-top: 50px;
}

.user-category-info > * {
    margin: 0;
    padding: 0;
}

.user-category-input-wrapper {
    position: relative;
    width: 200px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.user-category-info > h1 {
    font-size: 45px;
}

.all-user-category-images-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: center;
    gap: 20px;
    padding-top: 50px;
}

input[type=checkbox].user-category-input {
    width: 200px;
    height: 200px;
    cursor: pointer;
    opacity: 0;
}

input[type=checkbox].user-category-input:checked {
    width: 200px;
    height: 200px;
    cursor: pointer;
    opacity: 30%;
    accent-color: white;
    color: black;
}

.user-category-image {
    position: absolute;
    width: 200px;
    height: 200px;
    object-fit: cover;
    top: 0;
    left: 0;
    z-index: -2;
}

.individual-categories > p {
    margin: 0;
    padding: 0;
    padding-top: 15px;
    font-size: 20px;
    font-weight: 600;
}

.category-button {
    align-self: end;
    padding: 20px;
    border-radius: 50%;
    font-size: 20px;
} 
