.save-pins-to-board-modal-container {
  width: 900px;
  /* height: 600px; */
  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;
  border-radius: 15px;

}

.save-pin-list-container {
  overflow-y: scroll;
  /* padding: 0px 30px; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.save-pins-to-new-board-text {
  display: flex;
  align-items: center;
  font-size: 30px;
  justify-content: center;

}

.save-pins-to-board-modal-container::-webkit-scrollbar {
  width: 10px;
}


.save-pins-to-board-save-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: rgb(235, 4, 4);
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 20px;
}

.save-pins-to-board-save-button:hover {
  cursor: pointer;
  background-color: rgb(203, 3, 3);
}


/* .save-pins-to-board-modal-container::-webkit-scrollbar-track {
  background: transparent;

} */

/* .save-pins-to-board-modal-container::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  top: 30px;
} */

.save-pins-to-board-modal-container::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.3);

}

/* .date-div{
  text-align: center;
  padding-top: 25px;
} */

.save-pins-board-list {
  display: grid;
  height: 450px;
  width: 100%;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  padding: 0px 20px;
  /* margin-left:20px;
  margin-right:20px; */
  justify-content: center;
  position: relative;

}

.save-pins-to-board-modal-pins {
  display: flex;
  flex-direction: column;
  align-items: right;

  width: 200px;
  /* flex:0 0 0; */
  height: 275px;
  border-radius: 5%;
  color: white;
  object-fit: contain;

  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

/* .save-pins-to-board-modal-text-container {
  position: absolute;
  bottom: 0;
  left: 50%;
  margin: 0 auto;
  text-align: center;
} */


.bottom-done {
  width: 9%;
  height: 100px;
  background-color: white;
  position: sticky;
  bottom: 0px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 20px;
}

.bottom-done-text {
  color: white;
  background-color: red;
  border-radius: 15px;
  width: 70px;
  height: 50px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.bottom-done-text:hover {
  cursor: pointer;
  background-color: rgb(203, 3, 3);
}
