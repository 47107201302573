.profile-image {
    width: 125px;
    height: 125px;
    border-radius: 50%;
}

.profile-image-default {
    font-size: 125px;
}

.profile-page-base {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    row-gap: 10px;
}

.profile-page-base>* {
    margin: 0;
    padding: 0;
}

.profile-name-pronouns {
    display: flex;
    font-size: 13px;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
}

.profile-name-pronouns>* {
    font-size: 13px;
    color: gray;
    font-weight: 400;
}

.profile-user-name {
    font-size: 40px;
    font-weight: 500;
}

.profile-pronouns {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
}

.profile-pronouns-dot {
    font-size: 2px;
}

.profile-about-section {
    font-weight: 500;
    font-size: 13px;
}

.profile-followers-and-following {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 5px;
}

.profile-followers-and-following-dot {
    font-size: 2px;
}

.profile-button {
    padding: 10px;
    border-radius: 20px;
    font-size: 13px;
    border-color: transparent;
    cursor: pointer;
    font-weight: 700;
}

.profile-button:not(.edit-profile) {
    background-color: transparent;
}

.profile-plus-icon-wrapper {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    position: relative;
}

.profile-plus-button {
    margin: 0px 20px;
    font-size: 20px;
    border-radius: 100%;
    background-color: transparent;
    border: transparent;
    padding: 10px 13px;
    color: #111111;
    cursor: pointer;
}

.profile-plus-button:hover {
    background-color: #E0E0E0;
    padding: 10px 13px;
}

.hidden {
    display: none
}

.profile-menu {
    position: absolute;
    top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    right: 30px;
    top: 50px;
    background-color: white;
    box-shadow: 0 0 4px gray;
    width: 150px;
    border-radius: 10px;
    padding: 8px;
    z-index: 999;
}

.profile-dropdown-create-label {
    font-size: 12px;
    padding-left: 5px;
}

.profile-dropdown-create {
    font-size: 14px;
    padding: 8px 5px;
    /* margin-left: 5px; */
    margin-top: 5px;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    cursor: pointer;
}

.profile-dropdown-create:hover {
    background-color: #E9E9E9;
    /* border: 3px solid #7FC1FF; */
    border-radius: 10px;
}

#follow-button {
    background-color: red;
    color: white
}

#unfollow-button {
    background-color: red;
    color: white;
}

#unfollow-button:hover{
    background-color: rgb(216, 4, 4);
}


.underline {
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: 4px;
}

.user-profile-bottom-wrapper {
    width: 100%;
}
