.change-board-modal-container {
  width: 900px;
  height: 600px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  position: relative;
  border-radius: 15px;
}

.change-board-list-container {
  overflow-y: scroll;
  /* padding: 0px 30px; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.change-board-new-board-text {
  display: flex;
  align-items: center;
  font-size: 30px;
  justify-content: center;
}

.change-board-modal-container::-webkit-scrollbar {
  width: 10px;
}

.change-board-modal-container::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

.change-board-board-list {
  display: grid;
  height: 300px;
  width: 100%;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  padding: 0px 20px;
  /* margin-left:20px;
  margin-right:20px; */
  justify-content: center;
  position: relative;
}

.change-board-modal-pins {
  width: 200px;
  /* flex:0 0 0; */
  height: 275px;
  border-radius: 5%;
  color: white;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  background-size: cover;
  background-position: center;

}

.change-board-modal-pins:hover{
 opacity: 0.8;
 cursor: pointer;
}

.change-board-modal-text-container {
  position: absolute;
  bottom: 0;
  left: 50%;
  margin: 0 auto;
  text-align: center;
}

.change-board-bottom-done {
  width: 100%;
  height: 50px;
  background-color: white;
  position: sticky;
  bottom: 0px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-radius: 15px;
}

.change-board-bottom-done-text {
  color: white;
  background-color: red;
  border-radius: 15px;
  width: 70px;
  height: 50px;
  justify-content: center;
  align-items: center;
  display: flex;
}


.change-board-you-have-no-pins{
  padding: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.change-board-you-have-no-pins-add{
  margin-top: 50px;
  position: absolute;
  bottom: 10px;
}

.change-board-you-have-no-pins-x{
  position: absolute;
  top: 10px;
  right: 10px;
  font-weight: bold;
  cursor: pointer;
  /* padding: 10px; */
  background-color: transparent;
  border: 10px solid transparent;
  transition: font-size linear 0.2s ;
}

.change-board-you-have-no-pins-x:hover{
font-size: 22px;


}
