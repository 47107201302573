.pin-edit-title-input {
  border-radius: 15px;
  border: 2px solid #ccc;
  padding: 20px;
  width: 200px;
  height: 1px;
}

#testing {
  width: 150%;
  border: 2px solid red;
}

.board-dropdown {
  border-radius: 15px;
  border: 2px solid #ccc;
  padding: 15px;
  /* width: 200px; */
  width: 243px;
  /* height: 1px; */
  color: black;
}

.board-options {
  border-radius: 15px;
  border: 2px solid #ccc;
  padding: 20px;
  width: 200px;
  height: 1px;
  color: black;
}

.pin-edit-website-input {
  border-radius: 15px;
  border: 2px solid #ccc;
  padding: 20px;
  width: 200px;
  height: 1px;
}

.pin-edit-description-input {
  border-radius: 15px;
  border: 2px solid #ccc;
  padding: 20px;
  width: 200px;
  height: 50px;
}

.pin-edit-alt-text-input {
  border-radius: 15px;
  border: 2px solid #ccc;
  padding: 20px;
  width: 200px;
  height: 1px;
}

.pin-title-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 100px
}

.board-title-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 100px;
  /* font-family:-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",Helvetica,"ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"; */
}

.pin-description-container {
  display: flex;
  flex-direction: row;
  gap: 100px
}

.pin-website-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 100px
}

.pin-alt-text-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 25px;
  gap: 100px
}

.title {
  text-align: center;
}

.edt-pin-delete-button {
  float: left;
  background-color: #efefef;
  border: 0;
  width: 65px;
  height: 40px;
  border-radius: 24px;
  color: #151515;
  cursor: pointer;
}

.edt-pin-delete-button:hover{
  background-color: #dbdbdb;
}

.edit-pin-cancel-button {
  float: right;
  background-color: #efefef;
  border: 0;
  width: 65px;
  height: 40px;
  border-radius: 24px;
  color: #151515;
  cursor: pointer;
}

.edit-pin-cancel-button:hover{
  background-color: #dbdbdb;
}

.submit-and-cancel-buttons>button {
  padding: 10px;
}

.submit-and-cancel-buttons {
  padding-bottom: 20px;
}

.edit-pin-submit-button {
  float: right;
  background-color: #e60023;
  border: 0;
  width: 65px;
  height: 40px;
  border-radius: 24px;
  color: white;
  margin-left: 15px;
  cursor: pointer;
}

.edit-pin-submit-button:hover {
  background-color: rgb(203, 3, 2);
}

.edt-pin-delete-button-non-owner {
  float: left;
  background-color: #efefef;
  border: 0;
  width: 65px;
  height: 40px;
  border-radius: 24px;
  color: #151515;
  cursor: pointer;
}

.edit-pin-submit-button-non-owner {
  float: right;
  background-color: #e60023;
  border: 0;
  width: 65px;
  height: 40px;
  border-radius: 24px;
  color: white;
  cursor: pointer;
}

.edit-pin-cancel-button-non-owner {
  float: right;
  background-color: #efefef;
  border: 0;
  width: 65px;
  height: 40px;
  border-radius: 24px;
  color: #151515;
  cursor: pointer;
}

.edit-pin-title-with-errors {
  display: flex;
  flex-direction: column;
}


.pin-edit-owner-container {
  width: 500px;
}

.pin-edit-owner-inner-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
  /* width: 80%; */
}


.edit-board-errors {
  word-wrap: break-word;
  width: 240px;
  font-size: 10px;
  /* text-align: right; */
}


.edit-board-character-count {
  font-size: 10px;
  /* text-align: right; */
}

.board-dropdown-edit-pin {
    border-radius: 15px;
    border: 2px solid #ccc;
    padding: 10px;
    width: 300px;
}
