.delete-board-container {
    width: 300px;
    height: 150px;
    padding: 20px;
    display: grid;
    grid-template-rows: 1fr 1fr 8fr;
}

.delete-board-page-header {
    font-size: 20px;
    font-weight: 600;
}

.delete-board-page-caption {
    font-size: 15px;
    font-weight: 400;
}

.delete-board-page-buttons {
    justify-self: end;
    align-self: end;
    display: flex;
    column-gap: 5px;
}

.delete-board-page-buttons > button {
    padding: 10px;
    border-radius: 20px;
    font-size: 15px;
    border-color: transparent;
    cursor: pointer;
}

.delete-board-page-cancel-button {
    background-color: #efefef;
    color: black;
}

.delete-board-page-delete-button {
    background-color: #e60023;
    color: white;
}