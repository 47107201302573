.dropdown-container {
  text-align: left;
  border: 1px solid #ccc;
  position: relative;
  border-radius: 5px;

}

.dropdown-input {
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;
  /* width: 100%; */
  /* border: 2px solid red; */

}

.edit-pin-options {
  /* border: 2px solid red; */
  /* border: 2px solid red; */
  z-index: 4;
  width: 400px;
}

#dropdown-menu-wrapper {
  /* border: 2px solid purple; */
  width: 200px;
  overflow: hidden;
}

.single-pin-edit-board {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
