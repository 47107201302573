.profile-dropdown {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  top: 50px;
  right: 5px;
  height: 350px;
  width: 170px;
  background-color: white;
  border-radius: 15px;

  z-index: 9999;
  box-shadow: 3px 5px 5px rgba(0, 0, 0, 0.2);
  text-align: left;
  padding-right: 40px;
}

.profile-dropdown.hidden {
  display: none;
}

.profile-dropdown ul {
  list-style: none;
  padding: 10px;
}

.profile-dropdown li {
  margin-bottom: 10px;
  list-style: none;
  text-align: left;
}

.nav-dropdown-top-text {
  padding-top: 30px;
}

.tab-disabled{
  pointer-events: none;
  color: #ccc;
  cursor: not-allowed;
}

.nav-dropdown-middle-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 50px;
  text-align: center;
}

.nav-dropdown-middle-text{
  padding: auto;
}

.profile-dropdown a {
  text-decoration: none;
  color: #333333;
  transition: color 0.3s ease;
}

.profile-dropdown a:hover {
  color: #ff0000;
}

.profile-dropdown button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: #333333;
  transition: color 0.3s ease;
}

.profile-dropdown button:hover {
  color: #ff0000;
}


.hidden {
  display: none;
}

.nav-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  top: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 16px;
}

.left-section {
  display: flex;
  align-items: center;
  flex-grow: 1;

}

.right-section {
  margin-left: auto;
  display: flex;
  align-items: center;
  border: 2px red;
  /* flex-grow: 1; */
}

.logo {
  fill: rgb(230, 0, 35);
  display: block;
  height: 25px;
  width: 25px;
  font-size: 12px;
  stroke-width: 0px;
  cursor: auto;
}

.pinterest-logo {
  color: rgb(204, 0, 0);
  display: block;
  font-size: 17px;
  font-weight: 600;
  padding-left: 2.5px;
  text-decoration: none;
}

.tabs {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 16px;
}

.tab {
  color: black;
  text-decoration: none;
  margin-right: 0px;
  font-size: 13px;
  font-weight: 500;
  padding: 10px 10px;

}

.tab-create {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.tab-arrow {
  margin-left: 0px;
  margin-right: 10px;
  margin-top: 1px
}

.tab.active {
  color: white;
  padding: 10px 10px;
  background-color: black;
  border-radius: 15px;
  font-weight: 600;
}

.search-bar {
  flex: 1;
  min-width: 407px;
  padding: 8px;
  margin-right: 20px;
  border-radius: 20px;
  background-color: rgb(230, 230, 230);
  border: 0;
  font-size: 14px;
}

.nav-right {
  display: flex;
  flex-direction: row;
  margin-left: auto;
}

.nav-explore-pins-button,
.nav-log-in,
.nav-sign-up {
  color: white;
  border-radius: 24px;
  padding: 12px 13px;
  transition: background-color 0.3s ease;
  font-size: 13.5px;
  cursor: pointer;
  outline: none;
}

.search-button{
  color: black;
  border-radius: 24px;
  padding: 8px 13px;
  transition: background-color 0.3s ease;
  font-size: 13.5px;
  cursor: pointer;
  outline: none;
  border: 1px solid rgb(138, 138, 138);
  /* box-shadow: 1px 1px 10px 0px black; */
}

.search-button:hover{
  background-color: lightgrey;
}

.nav-log-in {
  background-color: #f00505;
  margin-right: 5px;
}

.nav-log-in:hover {
  background-color: darkred;
}

.nav-explore-pins-button {
  background-color: black;
  color: white;
  border-color: transparent;
  margin-right: 5px;

}

.nav-sign-up {
  background-color: #f0f0f0;
  font-weight: 400;
}

.nav-sign-up:hover {
  background-color: darkgray;
}

.open-modal-button {
  background-color: transparent;
  border: none;
}

.pinterest-svg {
  width: 30px;
  height: 30px;
  cursor: pointer;
}


.nav-icon-button {
  border: 1px solid black;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  margin-left: 15px;

}


.nav-profile-menu {
  position: absolute;
  /* top: 20px; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* right: 0px; */
  left: 1px;
  top: 50px;
  background-color: white;
  box-shadow: 0 0 4px gray;
  width: 150px;
  border-radius: 10px;
  padding: 8px;
  z-index: 999;

}

.profile-dropdown-create-label {
  font-size: 12px;
  padding-left: 5px;
}

.nav-profile-dropdown-create {
  font-size: 16px;
  color:black;
  padding: 8px 5px;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  text-decoration: none;
  cursor: pointer;
}

.profile-dropdown-create:hover {
  background-color: #E9E9E9;
  /* border: 3px solid #7FC1FF; */
  border-radius: 10px;
}


.nav-create-tab {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;

}

.flex-row {
  display: flex;
  flex-direction: row;
  cursor: pointer;

}

.nav-create {
  margin-right: 7px
}

.open-modal-button {
  cursor: pointer;
}


.nav-bar-right-arrow{
  margin-left: 10px;
  cursor: pointer;
  color: rgb(91, 91, 91);
}

.nav-bar-right-side-cursor{
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

}
