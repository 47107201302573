

.individual-board-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}

.individual-board-info-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40%;
}

.individual-board-info-owner > h1 {
    font-weight: 600;
}

.individual-board-info-owner {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
    position: relative;
}

.hidden {
    display: none;
}

.individual-board-ellipsis {
    padding: 5px;
    border: transparent;
    background-color: #E0E0E0;
    border-radius: 50%;
    cursor: pointer;
    font-size: 15px;
}

.board-ellipsis-wrapper:not(.hidden) {
    position: absolute;
    top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    right: 30px;
    top: 65px;
    background-color: white;
    box-shadow: 0 0 4px gray;
    width: 150px;
    border-radius: 10px;
    padding: 8px;
    padding: 13px;
    cursor: pointer;
}

.individual-board-info > h1 {
    padding: 0;
    margin: 0;
}

.individual-board-description-owner {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.individual-board-description {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    column-gap: 5px;
}

.board-dot {
    font-size: 2px;
}

.single-board-pins-wrapper {
    width: 100%;
    display: flex;
    justify-items: center;
}

.pins-for-board-page-wrapper{
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
}
.individual-board-pins-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    /* text-align: center; */
    padding-top: 15px;

    width: 100%;
    box-sizing: border-box;
    padding: 10px;
}

.individual-board-pins-wrapper-wrapper {
    display: flex;
    justify-content: center;
}

.individual-board-pin-image {
    width: 100%;
    height: auto;
    border-radius: 20px;
    object-fit: contain;
    /* padding-bottom: 10px; */
    cursor: zoom-in;
}

.individual-board-individual-pins-wrapper{
    display: grid;
    padding-bottom: 10px;
    width: 100%;
}


.individual-board-individual-pin-profile-image {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
}

.individual-board-profile-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.individual-board-profile-image.fa-solid.fa-circle-user {
    font-size: 50px;
}

.individual-board-info {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    row-gap: 10px;
    padding-bottom: 15px;
}

.individual-board-profile-link {
    text-decoration: none;
    font-weight: 500;
    color: black;
}

.individual-board-pin-title {
    font-weight: 500;
    font-size: 14px;
    width: 80%;
    cursor: zoom-in;
}

.individual-board-pin-owner-info {
    display: flex;
    align-items: center;
    column-gap: 5px;
    width: 100%;
    cursor: pointer;
    /* margin-left: 10px; */
}

.individual-board-pin-owner-info:hover {
    text-decoration: underline;
}

.individual-boards-link-to-pin {
    cursor: zoom-in;
    position: relative;
    width: 100%;
}

.singleboard-pins-length{
    width: 100%;
    box-sizing: border-box;
    font-size: 20px;
    padding-left: 30px;
    font-weight: 500;
}

.board-pin-masonry {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    justify-content: center;
    gap: 10px;
}

.board-pins-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}


.cursor-pointer{
    cursor: pointer;
}
.individual-board-edit-pin-button {
    position: absolute;
    display: none;
    right: 20px;
    bottom: 20px;
    border-radius: 50%;
    padding: 5px;
    border-color: transparent;
    background-color: rgba(255, 255, 255, .6);
    cursor: pointer;
}

.individual-board-edit-pin-button > i {
    font-size: 20px;
}

.individual-board-individual-pins-wrapper:hover {
    .individual-board-edit-pin-button {
        display: block;
    }
}

.edit-pin-board-wrapper {
    display: grid;
    justify-items: center;
    padding: 10px;
}

.edit-pin-board-title {
    font-size: 25px;
    font-weight: 600;
}

.edit-pin-board-content {
    display: flex;
    height: 300px;
    column-gap: 10px;
}

.move-pin-board-form-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.move-pin-board-form-wrapper > h2 {
    font-weight: 400;
    font-size: 20px;
}

.move-pin-board-form {
    display: flex;
    column-gap: 5px;
}

.edit-pin-board-image {
    max-height: 300px;
}

.edit-pin-board-buttons-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
}

.edit-pin-board-buttons, .edit-pin-board-buttons-save {
    border-color: transparent;
    padding: 7px;
    border-radius: 15px;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
}

.edit-pin-board-buttons {
    background-color: #efefef;
    color: black;
}

.edit-pin-board-buttons{
    background-color: #e2e2e2;
}

.edit-pin-board-buttons-save {
    background-color: red;
    color: white;
}

.edit-pin-board-buttons-save:hover {
    background-color: #ad081b;
}
