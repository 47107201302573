.settings-form {
  display: flex;
  flex-direction: column;
  width: 70%;
}

.photo-and-button {
  display: flex;
  align-items: center;
}

.settings-list{
  padding-left:35px;
  cursor: pointer;
}

.only-one-that-works{
  text-decoration-line: underline;
  text-decoration-thickness: 3px;
  cursor: pointer;
  border-radius: 5px;
}
.settings-text{
  border-radius: 5px;
}
.main-settings-div{
  display:flex;
  flex: row;
  gap:300px
}


.settings-text:hover {
  background-color: #efefef
}

.file-input-actual-button{
  outline:none;
  border: none;
  border-radius: 20px;
}


.only-one-that-works:hover {
  background-color: #efefef
}

.name-info{
  display:flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;

}

.first-name-input {
  border-radius: 15px;
  border: 2px solid #ccc;
  padding: 20px;
  width: 200px;
  height: 1px;
}

.input-about{
  border-radius: 15px;
  border: 2px solid #ccc;
  padding: 20px;
  width: 450px;
  height: 40px;
}

textarea {
  resize: none;
  font-family: Arial, Helvetica, sans-serif;
}

.website-input{
  border-radius: 15px;
  border: 2px solid #ccc;
  padding: 20px;
  width: 450px;
  height: 1px;
}

.username-input{
  border-radius: 15px;
  border: 2px solid #ccc;
  padding: 20px;
  width: 450px;
  height: 1px;
}

.pronoun-select-list {
  width: 490px;
}

.reset-button{
  margin: auto;
}

.save-button{
  margin:auto
}

.delete-button{
  margin:auto
}

.photo-and-button{
  display:flex;
  flex-direction: row;
  gap:25px;
}

#profile-picture-display {
  border-radius: 50%;
  width:95px;
  height:95px;
  object-fit: cover;
}

#file-input-actual-button{
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-weight: bold;
  height: 25px;
  width: 100px;
  background-color: #5F5F5F;
  border-radius: 20px;
  border-color: transparent;
  font-size: 18px;
  color: white;
  cursor: pointer;
}

.footer-div{
  display:flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 20px 0;
  width: 400px;
}

.reset-button > button{
  border:none;
  height:50px;
  width: 80px;
  background-color: #5F5F5F;
  color: white;
  font-weight: bold;
  border-radius: 40px;
}

.save-button{
  border:none;
  height:50px;
  width: 80px;
  background-color: #5F5F5F;
  color: white;
  font-weight: bold;
  border-radius: 40px;
}

.delete-button{
  border:none;
  height:50px;
  width: 80px;
  background-color:red;
  color:white;
  font-weight: bold;
  border-radius: 40px;
}

.settings-form-errors {
  color: red;
  font-size: 15px;
}