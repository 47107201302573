#modal-content {
  border-radius: 15px;
}



.login-form-modal-container {
  width: 400px;
  max-height: 500px;
  display: flex;
  flex-direction: column;
  background-color: rgb(243, 242, 242);
  align-items: center;
  background-color: white;
  border-radius: 50px;
  padding-bottom: 30px;

}

.login-form-modal-container form {
  display: flex;
  flex-direction: column;
  align-items: left;
  /* background-color: red; */
  height: 100%;
  width: 55%;
}

.login-form-modal-container form label {
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-bottom: 12px;
  font-size: 12px;
  vertical-align: middle;
}

.login-form-modal-container form input {
  border-radius: 16px;
  border: 2px lightgrey solid;
  height: 35px;
}

.login-page-welcome {
  color: rgb(51, 51, 51);
  font-size: 32px;
  font-weight: 600;
  letter-spacing: -1.2px;
  padding-left: 16px;
  padding-right: 16px;
  -webkit-font-smoothing: antialiased;
  word-break: keep-all;
  display: block;
  font-size: 2em;
  /* margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px; */
}

.signup-page-ideas {
  font-weight: normal;
  font-size: 16px;
}

.login-form-logo {
  margin-top: 50px;
  fill: rgb(230, 0, 35);
  display: block;
  height: 25px;
  width: 25px;
  /* font-size: 12px; */
  stroke-width: 0px;
  cursor: auto;
  /* background-color: red; */
  /* vertical-align: middle; */
}

.login-page-welcome {
  margin-bottom: 5px;
  padding-bottom: 10px;
  text-align: center;
}


.login-form-member {
  /* padding-top: 20px; */
  text-align: center;
  font-size: 8px;
}

.login-form-login-text {
  font-weight: bold;
}

.login-form-errors {
  color: red;
  font-size: 12px;
}

.login-form-button {
  color: white;
  border-radius: 15px;
  height: 30px;
  fill: red;
  background-color: rgb(230, 0, 35);
  border: 0px solid;
}

/* .login-form-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  background: grey;
} */


.login-form-demo-user-button {
  color: white;
  border-radius: 15px;
  height: 30px;
  fill: red;
  background-color: rgb(26, 108, 250);
  border: 0px solid;
  font-weight: bold;
}


.form-or {
  text-align: center;
  font-size: 10px;
  font-weight: bold;
  margin-top: 10px;
}
