.page-not-found-wrapper-wrapper {
  display: flex;
  flex-direction: column;
  font-size: 40px;
  align-items: center;
  justify-content: center;
  /* padding-top: 300px; */
  text-align: center;
  width: 100%;
}

.page-not-found-image {
  width: 300px;
  height: 300px;
}

.page-not-found-wrapper {
  width: 50%;
}
